/**
 * [getParam ]
 * @param  {String} name
 * @param  {String} url [default:location.href]
 * @return {String|Boolean}
 */
export function getParam(name, url) {
  if (typeof name !== 'string') return false;
  if (!url) url = window.location.href;
  // 当遇到name[xx]时，对方括号做一下转义为 name\[xxx\]，因为下面还需要使用name做正则
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function deepClone(source) {
  // If the source isn't an Object or Array, throw an error.
  if (!(source instanceof Object) || source instanceof Date || source instanceof String) {
    throw 'Only Objects or Arrays are supported.';
  }
  // Set the target data type before copying.
  var target = source instanceof Array ? [] : {};
  for (let prop in source) {
    // Make sure the property isn't on the protoype
    // eslint-disable-next-line no-prototype-builtins
    if (source instanceof Object && !(source instanceof Array) && !source.hasOwnProperty(prop)) {
      continue;
    }
    // If the current property is an Array or Object, recursively clone it, else copy it's value
    if (
      source[prop] instanceof Object &&
      !(source[prop] instanceof Date) &&
      !(source[prop] instanceof String)
    ) {
      target[prop] = deepClone(source[prop]);
    } else {
      target[prop] = source[prop];
    }
  }
  return target;
}

export function unique(arr) {
  var array = arr;
  var len = array.length;

  array.sort(function (a, b) {
    //排序后更加方便去重
    return a - b;
  });

  function loop(index) {
    if (index >= 1) {
      if (array[index] === array[index - 1]) {
        array.splice(index, 1);
      }
      loop(index - 1); //递归loop，然后数组去重
    }
  }
  loop(len - 1);
  return array;
}

export function loadDevTool() {
  const script = document.createElement('script');
  script.src = '//cdn.bootcdn.net/ajax/libs/eruda/2.4.1/eruda.min.js';
  document.body.appendChild(script);
  script.onload = function () {
    window.eruda.init();
  };
}
