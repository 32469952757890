import Vue from 'vue';
import Router from 'vue-router';

import {
  Button,
  Cell,
  CellGroup,
  Col,
  Checkbox,
  CheckboxGroup,
  Field,
  Icon,
  ImagePreview,
  NavBar,
  RadioGroup,
  Radio,
  Rate,
  Row,
  Skeleton,
} from 'vant';


import App from './App.vue';
import { loadDevTool } from './utils/assists';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'survey',
      component: () => import(/* webpackChunkName: "survey" */ './views/Survey.vue'),
    },
    {
      path: '/result',
      name: 'result',
      component: () => import(/* webpackChunkName: "result" */ './views/Result.vue'),
    },
    {
      path: '*',
      redirect: '/result?code=30001',
    },
  ],
});

Vue.component(Button.name, Button);
Vue.component(Cell.name, Cell);
Vue.component(CellGroup.name, CellGroup);
Vue.component(Col.name, Col);
Vue.component(Checkbox.name, Checkbox);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Field.name, Field);
Vue.component(Icon.name, Icon);
Vue.component(ImagePreview.name, ImagePreview);
Vue.component(NavBar.name, NavBar);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(Radio.name, Radio);
Vue.component(Row.name, Row);
Vue.component(Rate.name, Rate);
Vue.component(Skeleton.name, Skeleton);

if (process.env.VUE_APP_BUILD_MODE === 'testing') {
  loadDevTool();
}

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
