<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    mounted() {
      window.doAppAction = (data) => {
        const { action } = data;
        console.log(`%c app action: ${action}`, 'color: #12B887');
      };
      const ua = window.navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua) && window.webkit) {
        window.doAppAction = (data) => window.webkit.messageHandlers.doAppAction.postMessage(data);
      }
      if (/android/.test(ua) && window.androidobj) {
        window.doAppAction = (data) => window.androidobj.doAppAction(JSON.stringify(data));
      }
    },
  };
</script>

<style>
  #app {
    box-sizing: border-box;
    width: 100vw;
    min-height: 100vh;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: #ffffff;
    -webkit-overflow-scrolling: touch;
  }
</style>
